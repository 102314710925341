import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import Solutions from "/src/components/pages/solutions/Solutions";
import Benefits from "/src/components/pages/products/Benefits";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";

import circuit2 from "/static/assets/animations/circuit/circuit01.json";

const IdentityGovernance = ({data}) => {

    const {governance} = data

    return (
        <Layout lang={governance.lang} seo={governance.SEO}>
            <LottieAnimation animationData={circuit2} left="true"/>
            <Solutions
                title={governance.title}
                subTitle={governance.challenges.title}
                challangesCards={governance.challenges.solution_card}
                subTitle2={governance.solutions.title}
                solutionCards={governance.solutions.solution_card}
                button={governance.solutions_button}
                buttonStyle="one-identity-primary"
            />
            <Benefits
                small_title={governance.benefits_title}
                benefits={governance.benefits_card}
                button={governance.benefits_button}
                button_style="one-identity-primary"
                customPadding="noTop"
            />
        </Layout>
    )
}

export const query = graphql`
   query GetSingleIdentityGovernance($locale: String) {
         governance: strapiIdentityGovernances(lang: { eq: $locale }) {
          lang
          title
          challenges {
            id
            title
            solution_card {
              avatar {
                url
              }
              title
              description
              id
            }
          }
          solutions {
            id
            title
            solution_card {
              avatar {
                url
              }
              title
              description
              id
            }
          }
          solutions_button {
            id
            link
            name
          }
          benefits_title
          benefits_card {
            id
            title
          }
          benefits_button {
            id
            link
            name
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
   }
 `

export default IdentityGovernance